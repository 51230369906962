<template>
    <el-dialog
            title="调价详情"
            :append-to-body="true"
            :close-on-click-modal="false"
            :before-close="cancel"
            :visible.sync="dialogFormVisible"
            style="width: 115%;"
    >
        <div class="tree1">
            <div class="tree">
              <div v-if="data.orderSkuVoList[0].applyMessage != null && data.orderSkuVoList[0].applyMessage != ''" 
                style="padding: 10px 0px;font-size: 15px;">
                <div class="xiaoji">审批意见:{{ data.orderSkuVoList[0].applyMessage }}</div></div>
                <div class="tree-title">
                    <div class="shangpin">商品</div>
                    <div class="danjia">单价</div>
                    <!--          <div class="danjia">建议价</div>-->
                    <div class="danjia">优惠价</div>
                    <div class="shuliang">数量</div>
                    <div class="xiaoji">小计</div>
                    <div class="tiaojia">调价原因</div>
                    <div class="caozuo">状态</div>
                </div>
                <!-- 列表 -->
                <Loading v-if="isLoading" />
                <div v-else>
                    <div class="tree-merchant">
                        <!-- 商品信息 -->
                        <div
                                class="tree-goods"
                                v-for="(goods, indexs) in data.orderSkuVoList"
                                :key="indexs"
                        >
                            <!-- 图片 -->
                            <div class="goods-pic">
                                <Uimage :src="goods.defaultImg" alt="" />
                            </div>
                            <!-- 介绍 -->
                            <div class="goods-jieshao">
                                {{ goods.spuName }}
                                <!-- 商品属性 -->
                                <div v-for="(q, c) in goods.saleAttrCode" :key="c" class="shuxing">
                                  <div v-if="q.attrName">{{ q.attrName }}：</div>
                                  <div v-if="q.attrValue" v-html="q.attrValue"></div>
                                </div>
                            </div>
                            <!-- 含量 -->
                            <!-- <div class="goods-xinghao">
                                              <div class="goods-xinghao-list">
                                              </div>
                                          </div> -->
                            <!-- 单价 -->
                            <div class="goods-danjia1">
                                ￥{{ Number(goods.skuPrice).toFixed(2) }}
                            </div>
                            <!-- 建议价 -->
                            <!--              <div class="goods-danjia1">-->
                            <!--                ￥{{ Number(goods.price1).toFixed(2) }}-->
                            <!--              </div>-->
                            <!-- 优惠价 -->
                            <div class="goods-danjia1">
                                ￥{{ Number(goods.skuRealPrice).toFixed(2) }}
                            </div>
                            <!-- 数量 -->
                            <div class="goods-shuliang1">
                                {{ goods.skuCount }}
                            </div>
                            <!-- 小计 -->
                            <div class="goods-xiaoji1">
                                ￥{{ Number(goods.commodityAmount).toFixed(2) }}
                            </div>
                            <!-- 调价内容 -->
                            <div class="goods-tiaojia">
                              {{ goods.remarkTj }}
                            </div>
                            <div class="goods-caozuo1"  v-if="goods.applyStatus == 2||goods.applyStatus == 3">
                                审核中
                            </div>
                            <div class="goods-caozuo1"  v-if="goods.applyStatus == 0">
                                审核通过
                            </div>
                            <div class="goods-caozuo1"  v-if="goods.applyStatus == 1">
                                审核不通过&nbsp;&nbsp;
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="jiesuan">
                <div class="tree-bottom_C">
                    <div class="jiesuan_L"></div>
                    <div class="jiesuan_R">
                        <div class="yixuanze"></div>
                        <div class="zongjia">
                            总金额：<span class="money">￥{{ oldAllPrice }}</span>
                        </div>
                        <div class="zongjia">
                            已抵扣金额：<span class="money">￥{{ data.cardAmount+data.sellerDiscount }}</span>
                        </div>
                        <div class="zongjia">
                            运费：<span class="money">￥{{ data.orderFreight }}</span>
                        </div>
                        <div class="zongjia">
                            优惠后金额：<span class="money">￥{{ preAllPrice }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-input style="display: none" v-model="orderId" />
    </el-dialog>
</template>

<script>
    import { post, get } from '@/utils/request';
    import { toDecimal2 } from '@/utils/numberUtils.js';

    export default {
        data() {
            return {
                isLoading: false,
                dialogFormVisible: false,
                orderId: '',
                data: [],
                oldAllPrice: 0.0,
                preAllPrice: 0.0,
                sfGp: false,
            };
        },
        created() {
            // this.getOrderSku();
        },
        methods: {
            // 初始化加载列表数据
            getOrderSku() {
                if (this.orderId != null) {
                    this.isLoading = true;
                    this.sfGp = false;
                    get('api/orderbasic/getorderbyid?orderId=' + this.orderId).then(
                        (res) => {
                            for (let q = 0; q < res.data.data.orderSkuVoList.length; q++) {
                            for (let j = q+1; j < res.data.data.orderSkuVoList.length; j++) {
                                if(res.data.data.orderSkuVoList[q].remark =='1'&& res.data.data.orderSkuVoList[j].remark=='1'){
                                    if (res.data.data.orderSkuVoList[q].skuName == res.data.data.orderSkuVoList[j].skuName&&res.data.data.orderSkuVoList[q].storeId == res.data.data.orderSkuVoList[j].storeId&&res.data.data.orderSkuVoList[q].prodCatId == res.data.data.orderSkuVoList[j].prodCatId&&res.data.data.orderSkuVoList[q].batchNumber == res.data.data.orderSkuVoList[j].batchNumber) {
                                    res.data.data.orderSkuVoList.splice(j,1);
                                    }
                                }
                                }
                            }
                             for (let q = 0; q < res.data.data.orderSkuVoList.length; q++) {
                            for (let j = q+1; j < res.data.data.orderSkuVoList.length; j++) {
                                if(res.data.data.orderSkuVoList[q].remark =='1'&& res.data.data.orderSkuVoList[j].remark=='1'){
                                    if (res.data.data.orderSkuVoList[q].skuName == res.data.data.orderSkuVoList[j].skuName&&res.data.data.orderSkuVoList[q].storeId == res.data.data.orderSkuVoList[j].storeId&&res.data.data.orderSkuVoList[q].prodCatId == res.data.data.orderSkuVoList[j].prodCatId&&res.data.data.orderSkuVoList[q].batchNumber == res.data.data.orderSkuVoList[j].batchNumber) {
                                    res.data.data.orderSkuVoList.splice(j,1);
                                    }
                                }
                                }
                            }
                             for (let q = 0; q < res.data.data.orderSkuVoList.length; q++) {
                            for (let j = q+1; j < res.data.data.orderSkuVoList.length; j++) {
                                if(res.data.data.orderSkuVoList[q].remark =='1'&& res.data.data.orderSkuVoList[j].remark=='1'){
                                    if (res.data.data.orderSkuVoList[q].skuName == res.data.data.orderSkuVoList[j].skuName&&res.data.data.orderSkuVoList[q].storeId == res.data.data.orderSkuVoList[j].storeId&&res.data.data.orderSkuVoList[q].prodCatId == res.data.data.orderSkuVoList[j].prodCatId&&res.data.data.orderSkuVoList[q].batchNumber == res.data.data.orderSkuVoList[j].batchNumber) {
                                    res.data.data.orderSkuVoList.splice(j,1);
                                    }
                                }
                                }
                            }

                            res.data.data.orderSkuVoList.forEach((q) => {
                              q.saleAttrCode = eval('(' + q.contentValue + ')');
                              if (q.value != '0mol/mol' && q.value != '0ppm' && q.value != null) {
                                if (q.value.indexOf('mol/mol') != -1) {
                                  q.saleAttrCode.push({
                                    attrName: '确认含量',
                                    attrValue: q.value.replace(
                                      'mol/mol',
                                      'X10<sup>-6</sup>mol/mol'
                                    ),
                                  });
                                } else {
                                  q.saleAttrCode.push({
                                    attrName: '确认含量',
                                    attrValue: q.value,
                                  });
                                }
                              }
                              q.saleAttrCode.forEach((k, index) => {
                                if (k.attrName == '浓度范围') {
                                  q.saleAttrCode.splice(index, 1);
                                }
                                if((k.attrName == '包装物' || k.attrName == '包装类型')  && k.attrValue == '购瓶'){
                                  this.sfGp = true;
                                }
                              });
                              Object.assign(q, { isTrue: 'false' });
                            });
                            for (let q = 0; q < res.data.data.orderSkuVoList.length; q++) {
                              for (let j = q + 1; j < res.data.data.orderSkuVoList.length; j++) {
                                if(res.data.data.orderSkuVoList[q].remark =='1'&& res.data.data.orderSkuVoList[j].remark=='1'){
                                  if (res.data.data.orderSkuVoList[q].skuName == res.data.data.orderSkuVoList[j].skuName&&res.data.data.orderSkuVoList[q].storeId == res.data.data.orderSkuVoList[j].storeId&&res.data.data.orderSkuVoList[q].prodCatId == res.data.data.orderSkuVoList[j].prodCatId&&res.data.data.orderSkuVoList[q].batchNumber == res.data.data.orderSkuVoList[j].batchNumber) {
                                    for (
                                      let p = 0;
                                      p < res.data.data.orderSkuVoList[j].saleAttrCode.length;
                                      p++
                                    ) {
                                      if (
                                        res.data.data.orderSkuVoList[j].saleAttrCode[p].attrName == '多组分' ||
                                        res.data.data.orderSkuVoList[j].saleAttrCode[p].attrName == '气体组分' ||
                                        res.data.data.orderSkuVoList[j].saleAttrCode[p].attrName == '确认含量'
                                      ) {
                                        res.data.data.orderSkuVoList[q].saleAttrCode.push(
                                          res.data.data.orderSkuVoList[j].saleAttrCode[p]
                                        );
                                      }
                                    }
                                  }
                                }
                              }
                            }
                            this.isLoading = false;
                            this.data = res.data.data;
                            this.sumOldAllPrice();
                            this.sumPreAllPrice();
                        }
                    );
                }
                return true;
            },
            //合计原总价
            sumOldAllPrice() {
                let newArr = [];
                this.data.orderSkuVoList.forEach((res) => {
                    newArr.push(res);
                });
                this.oldAllPrice = 0;
                newArr = Array.from(new Set(newArr));
                newArr.forEach((res) => {
                    this.oldAllPrice += res.skuPrice * res.skuCount;
                });
                this.oldAllPrice = toDecimal2(this.oldAllPrice);
            },
            //合计优惠后总价
            sumPreAllPrice() {
                let newArr = [];
                this.data.orderSkuVoList.forEach((res) => {
                    newArr.push(res);
                });
                this.preAllPrice = 0;
                newArr = Array.from(new Set(newArr));
                newArr.forEach((res) => {
                    // 初始化商品优惠价格
                    this.preAllPrice += res.skuRealPrice * res.skuCount;
                    // 初始化商品小计
                    res.commodityAmount = res.skuRealPrice * res.skuCount;
                });
                this.preAllPrice = toDecimal2(this.preAllPrice);
            },
            // 取消
            cancel() {
                this.resetAddForm();
            },
            // 刷新
            resetAddForm() {
                this.dialogFormVisible = false;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .tishi {
        width: 90%;
        text-align: center;
        margin-top: 150px;
        font-size: 24px;
        color: rgb(139, 135, 135);
    }
    .right {
        width: 1006px;
        padding-top: 34px;
        padding-left: 27px;
        background: #fff;
        .spen {
            font-size: 26px;
            font-weight: 600;
            color: #616161;
        }
        .menu {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .daohang {
                font-size: 15px;
                color: #272727;
                margin-right: 25px;
                height: 30px;

                .ul {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0;
                    width: 400px;
                    li {
                        list-style: none;
                        cursor: pointer;
                        &.router-link-exact-active.router-link-active {
                            color: #3661fe;
                        }
                    }
                }
            }
            .search {
                margin-right: 150px;
                display: flex;
                input {
                    border: 0;
                    outline: none;
                    border: 1px solid #a7a7a7;
                    height: 37px;
                    padding-left: 10px;
                }
                .fangdajing {
                    width: 45px;
                    height: 40px;
                    border: 1px solid #a7a7a7;
                    border-left: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
        .fenye {
            margin-top: 50px;
            margin-bottom: 20px;
        }
        .top {
            width: 100%;
            height: 42px;
            line-height: 42px;
            background: #f5f5f5;
            display: flex;
            margin-top: 30px;
            .biaoti {
                font-size: 16px;
                color: #888;
                margin-left: 20px;
                .danhao {
                    color: #666;
                    font-weight: 800;
                }
                img {
                    width: 23px;
                    height: 23px;
                    margin-left: 10px;
                }
            }
            .mar {
                margin-left: 40px;
            }
            .kefu {
                display: flex;
                align-items: center;
            }
        }
        .bottom {
            width: 100%;
            height: 137px;

            display: flex;
            .pic {
                width: 180px;
                height: 136px;
                border-bottom: 1px solid #f5f5f5;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .title1 {
                height: 100%;
                border-right: 1px solid #eaeaea;
                border-bottom: 1px solid #f5f5f5;
                .title {
                    width: 240px;
                    padding-top: 34px;
                    display: flex;
                    .miaoshu {
                        color: #333;
                        width: 190px;
                        height: 40px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                    }
                    .shuliang {
                        margin-left: 25px;
                    }
                }
                .xiangsi {
                    font-size: 14px;
                    color: #9a9a9a;
                    margin-top: 10px;
                }
            }
            .tousu {
                width: 102px;
                border-right: 1px solid #eaeaea;
                text-align: center;
                padding-top: 34px;
                font-size: 14px;
                color: #333;
            }
            .beidaigouren {
                width: 158px;
                border-right: 1px solid #eaeaea;
                padding-top: 34px;
                padding-left: 25px;
            }
            .price {
                width: 158px;
                border-right: 1px solid #eaeaea;
                padding-top: 34px;
                padding-left: 25px;
                .jiage {
                    font-size: 14px;
                    color: #333;
                }
                .yunfei {
                    color: #a0a0a0;
                    font-size: 14px;
                }
                .fangshi {
                    color: #a0a0a0;
                    font-size: 14px;
                }
            }
            .zhuangtai {
                width: 130px;
                padding-top: 34px;
                border-right: 1px solid #eaeaea;
                text-align: center;
                color: #333;
                .xiangqing {
                    margin-top: 5px;
                    cursor: pointer;
                }
                .wuliu {
                    color: #dc7413;
                    margin-top: 5px;
                }
                .quxiao {
                    color: #a0a0a0;
                    margin-top: 5px;
                }
            }
            .pay {
                width: 136px;
                padding-top: 34px;
                border-right: 1px solid #eaeaea;
                font-size: 12px;
                color: #333;
                text-align: center;
                .liji {
                    color: #fff;
                    background: #1850ff;
                    padding: 5px 10px;
                    width: 85px;
                    border-radius: 5px;
                    margin: 0 auto;
                    cursor: pointer;
                }
                .liji1 {
                    color: #fff;
                    background: #ff902a;
                    padding: 5px 10px;
                    width: 85px;
                    border-radius: 5px;
                    margin: 0 auto;
                    margin-top: 10px;
                    cursor: pointer;
                }
                .daifu {
                    cursor: pointer;
                    margin-top: 5px;
                }
                .quxiao {
                    cursor: pointer;
                    margin-top: 5px;
                }
            }
        }
    }
    .tree1 {
        /*width: 100%;*/
        background: #f5f5f5;
        .tree {
            /*width: 1236px;*/
            margin: 0 auto;
            background: #fff;
            padding: 49px 10px 70px 10px;
            .tree-title {
                display: flex;
                align-items: center;
                padding: 10px 0 10px 10px;
                background: #f3f3f3;
                border: 1px solid #e9e9e9;
                .shangpin {
                    width: 260px;
                    margin-left: 140px;
                }
                .danjia {
                    width: 167px;
                    text-align: center;
                }
                .shuliang {
                    width: 130px;
                    text-align: center;
                }
                .xiaoji {
                    text-align: center;
                    width: 170px;
                }
                .tiaojia{
                  text-align: center;
                  width: 170px;
                }
                .caozuo {
                    text-align: center;
                    width: 150px;
                }
            }
            .tree-merchant {
                margin: 10px 0px;
                .tree-merchant-name {
                    display: flex;
                    align-items: center;
                    padding: 5px 10px;
                    border-bottom: 2px solid #999;
                }
                .tree-goods {
                    font-size: 14px;
                    color: #4c4c4c;
                    display: flex;
                    padding-top: 10px;
                    height: 180px;
                    border: 1px solid #e4e4e4;
                    padding-left: 10px;
                    .goods-pic {
                        width: 130px;
                        height: 126px;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .goods-jieshao {
                        width: 240px;
                        height: 180px;
                        padding: 20px 10px 0 10px;
                        margin-left: 10px;
                        overflow: hidden;
                    }
                    .goods-xinghao {
                        width: 180px;
                        padding-top: 15px;
                        margin-left: 20px;
                        .goods-xinghao-list {
                            display: flex;
                            margin-top: 5px;
                            color: #9c9c9c;
                        }
                    }
                    .goods-danjia1 {
                        width: 160px;
                        padding-top: 20px;
                        text-align: center;
                    }
                    .goods-shuliang1 {
                        width: 100px;
                        padding-top: 20px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        /*margin-left: 20px;*/
                        .youhuo {
                            color: #a8a8a8;
                            margin-top: 10px;
                        }
                        .wuhuo {
                            color: #e83f38;
                        }
                        &-num {
                            // display: flex;
                            // align-items: center;
                            margin-left: 5px;
                            .L {
                                cursor: pointer;
                            }
                            .R {
                                cursor: pointer;
                                margin-left: 10px;
                            }
                            .C {
                                /*width: 50px;*/
                                margin-left: 10px;
                                input {
                                    width: 40px;
                                    /*border: none;*/
                                    /*outline: none;*/
                                    text-align: center;
                                    padding: 0 10px;
                                    margin-bottom: 10px;
                                    background: #fff;
                                }
                            }
                        }
                    }
                    .goods-xiaoji1 {
                        width: 180px;
                        padding-top: 20px;
                        text-align: center;
                    }
                    .goods-tiaojia{
                      width: 145px;
                      padding-top: 20px;
                      text-align: center;
                    }
                    .goods-caozuo1 {
                        width: 130px;
                        padding-top: 20px;
                        text-align: center;
                        color: #343434;
                        .shan {
                            cursor: pointer;
                        }
                        .yiru {
                            cursor: pointer;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
        .jiesuan {
            width: 100%;
            height: 100px;
            background: #fff;
            .tree-bottom_C {
                /*width: 1150px;*/
                height: 80px;
                margin: 10px auto;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .jiesuan_L {
                    display: flex;
                    align-items: center;
                    .quanxuan {
                        padding-top: 5px;
                        color: #343434;
                    }
                    .shanchu {
                        margin-left: 20px;
                        color: #484848;
                        padding-top: 5px;
                        cursor: pointer;
                    }
                    .shanchu:hover {
                        color: red;
                        border-bottom: 1px solid red;
                    }
                    .yiru {
                        margin-left: 20px;
                        color: #484848;
                        padding-top: 5px;
                        cursor: pointer;
                    }
                    .yiru:hover {
                        border-bottom: 1px solid #484848;
                    }
                }
                .jiesuan_R {
                    font-size: 14px;
                    color: #989898;
                    display: flex;
                    align-items: center;
                    .yixuanze {
                        .spen {
                            color: #1a50fb;
                        }
                    }
                    .img {
                        width: 15px;
                        height: 9px;
                        margin-left: 10px;
                    }
                    .zongjia {
                        margin-left: 40px;
                        .money {
                            color: #1b50fe;
                        }
                    }
                    .tijiao {
                        width: 84px;
                        height: 28px;
                        line-height: 28px;
                        text-align: center;
                        background: #1850ff;
                        color: #fff;
                        border-radius: 5px;
                        margin-left: 17px;
                        cursor: pointer;
                    }
                }
            }
        }
        input {
            appearance: none;
            outline: none;
            margin-top: 10px;
            padding: 0;
            margin-right: 10px;
        }
        input[type='checkbox'] {
            position: relative;
            width: 20px;
            height: 20px;
            background: #d5d5d5;
            border: 1px solid #d8d8d8;
            border-radius: 4px;
            &:checked::after {
                content: '';
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                color: #333;
                text-align: center;
                line-height: 18px;
                background: url('https://static.98ep.com/img/BigCompany/a.png') no-repeat
                center;
            }
        }

        input[type='checkbox']:checked::after {
            content: '';
            background-color: #037df3;
            border-color: #037df3;
            background-color: #037df3;
        }
        input[type='checkbox']:checked::before {
            content: '';
            position: absolute;
            top: 2px;
            left: 5px;
            width: 6px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            z-index: 1;
        }
        button {
            margin-top: 20px;
        }
    }

    .shuxing {
    margin-left: 10px;
    font-size: 12px;
    color: #9a9a9a;
    /* margin-top: 10px; */
    display: flex;
}
</style>
