<template>
  <div class="right">
    <span class="spen">订单审核</span>
    <!-- 导航菜单 -->
    <div class="menu">
      <div class="daohang"></div>
      <div class="search">
        <input type="text" v-model="mes" placeholder="输入商品名称订单号" />
        <div class="fangdajing" @click="search">
          <img src="../../../assets/order/fangdajing.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 正在加载 -->
    <Loading v-if="isLoading" />
    <div v-else>
      <!-- 列表 -->
      <div class="tishi" v-if="order_list == ''">{{ tishi }}</div>
      <div v-for="(item, index) in order_list" :key="index">
        <div class="top">
          <div class="biaoti">{{ item.createTime }}</div>
          <div class="biaoti mar">
            订单号：<span class="danhao">{{ item.orderNo }}</span>
          </div>
          <div class="biaoti mar">店铺：{{ item.storeName }}</div>
          <!--          <div class="biaoti mar kefu">-->
          <!--            <span>客服</span><Uimage src="../../../assets/order/kefu.png" />-->
          <!--          </div>-->
        </div>
        <div class="bottom" v-for="(res, k) in item.orderSkuList" :key="k">
          <div class="pic"><Uimage :src="res.url" alt="" /></div>
          <div class="title1">
            <div class="title">
              <div class="miaoshu">{{ res.spuName }}</div>
              <!-- <div class="shuliang">￥{{ Number(res.skuPrice).toFixed(2) }}</div> -->
              <div class="shuliang">X{{ res.skuCount }}</div>
            </div>
            <!-- 商品属性 -->
            <div class="shuxing" v-for="(q, c) in res.saleAttrCode" :key="c">
              <div v-if="q.attrName">{{ q.attrName }}：</div>
              <div v-if="q.attrValue" v-html="q.attrValue"></div>
            </div>
          </div>
          <!-- <div class="tousu" style="border-bottom: 1px solid #f5f5f5;">
            <span v-if="res.isTrue == 'true'">投诉卖家</span>
          </div> -->
          <div class="tousu" style="border-bottom: 1px solid #f5f5f5;">
            <span v-if="res.isTrue == 'true'">{{item.companyName}}</span>
          </div>
          <div class="beidaigouren" style="border-bottom: 1px solid #f5f5f5;">
            <span v-if="res.isTrue == 'true'">{{ item.memberName }}</span>
          </div>
          <div class="price" style="border-bottom: 1px solid #f5f5f5;">
            <div class="jiage" v-if="item.orderSource == 4">
              {{ res.useScore }}
            </div>
            <div class="jiage" v-else>
              ￥{{ Number(res.skuRealPrice * res.skuCount).toFixed(2) }}
            </div>
            <!-- <div class="yunfei">
              (含运费:￥{{ Number(res.freight).toFixed(2) }})
            </div> -->
            <div class="fangshi" v-if="item.orderSource == 4">积分兑换</div>
            <div class="fangshi" v-else>在线支付</div>
          </div>
          <div class="zhuangtai" style="border-bottom: 1px solid #f5f5f5;">

            <div v-if="item.applyStatus == 0 && res.isTrue == 'true'">未申请</div>
            <div v-if="item.applyStatus == 1 && res.isTrue == 'true'">已申请</div>
            <div v-if="item.applyStatus == 2 && res.isTrue == 'true'">审核通过</div>
            <div v-if="item.applyStatus == 3 && res.isTrue == 'true'">审核拒绝</div>

            <!--订单状态-->
            <div v-if="item.orderStatus == 0&&res.isTrue == 'true'">待付款</div>
            <div v-if="item.orderStatus == 1&&res.isTrue == 'true'">待发货</div>
            <div v-if="item.orderStatus == 2&&res.isTrue == 'true'">待收货</div>
            <!--评价状态-->
            <div v-if="(item.orderStatus == 3 || item.orderStatus == 4)&&res.isTrue == 'true'">
              <div v-if="res.commentStatus == 0">
                待评价
              </div>
              <div v-if="res.commentStatus == 1">
                买家已评价
              </div>
              <div v-if="res.commentStatus == 2">
                卖家已评价
              </div>
              <div v-if="res.commentStatus == 3">
                双方已评
              </div>
            </div>
            <div v-if="item.orderStatus == 4 && res.isTrue == 'true'">交易完成</div>
            <div v-if="item.orderStatus == 5 && res.isTrue == 'true'">退款中</div>
            <div v-if="item.orderStatus == 6 && res.isTrue == 'true'">已退款</div>
            <div v-if="item.orderStatus == 7 && res.isTrue == 'true'">已删除</div>
            <div v-if="item.orderStatus == 10 && res.isTrue == 'true'">交易关闭</div>
            <div v-if="item.orderStatus == 20 && res.isTrue == 'true'">超时交易关闭</div>
            <!--订单详情-->
            <div class="xiangqing" @click="ding_detail(res)" v-if="res.isTrue == 'true'">订单详情</div>
            <!--查看物流-->
            <div
              class="wuliu"
              v-if="
                (item.orderStatus == 2 ||
                  item.orderStatus == 3 ||
                  item.orderStatus == 4)&&res.isTrue == 'true'
              "
              @click="showLogistics(item)"
            >
              查看物流
            </div>
            <div
              class="wuliu"
              v-if="item.orderStatus == 5 && item.deliveryTime != ''"
              @click="showLogistics(item)"
            >
              查看物流
            </div>
            <div
              class="wuliu"
              v-if="item.orderStatus == 6 && item.deliveryTime != ''"
              @click="showLogistics(item)"
            >
              查看物流
            </div>
          </div>
          <div class="pay" style="border-bottom: 1px solid #f5f5f5;">
            <div
              class="liji"
              v-if="item.orderStatus == 0 && item.confirmStatus == 0 && (item.applyStatus == 0 || item.applyStatus == 2 || item.applyStatus == 3) && res.isTrue == 'true'&&item.orderSource!=4"
              @click="tiaojia(res)"
            >
              商品调价
            </div>

            <div
              class="liji1"
              v-if="(item.applyStatus == 1 ||
                    item.applyStatus == 2 ||
                    item.applyStatus == 3) && res.isTrue == 'true'
              "
              @click="tiaojia_detail(res)"
            >
              调价详情
            </div>
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <div class="fenye" v-if="order_list != ''">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="totalNum"
          @current-change="pagechange"
          :current-page.sync="currentPage"
          v-if="totalNum != 0"
        ></el-pagination>
      </div>

      <!-- 查看物流 -->
      <el-dialog title="物流" :visible.sync="dialogFormVisible1">
        <div class="block">
          <el-timeline>
            <el-timeline-item
              v-for="(activity, index) in wuliu_list"
              :key="index"
              :timestamp="activity.time"
              :color="activity.ftime"
            >
              {{ activity.context }}
            </el-timeline-item>
          </el-timeline>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible1 = false">取 消</el-button>
        </div>
      </el-dialog>
    </div>

    <!--表单组件-->
    <eForm ref="form" />
    <dForm ref="dform" />
  </div>
</template>
<script>
import { post, get } from '@/utils/request';
import { setContextData, getContextData } from '../../../utils/session.js';
import eForm from './tiaojia';
import dForm from './tiaojia_detail';
export default {
  components: { eForm, dForm },
  data() {
    return {
      order_list: [],
      data: [],
      dialogFormVisible: false,
      isLoading: true,
      mes: '',
      tishi: '空空如也~',
      currentPage: 1,
      totalNum: 1,
      wuliu_list: [],
      isLoading1: false,
      dialogFormVisible1: false,
    };
  },
  created() {
    this.currentPage = getContextData('currentPage_sh') || 1;
    this.getorder(this.currentPage);
  },
  methods: {
    // 分页改变
    pagechange(p) {
      this.isLoading = true;
      this.currentPage = p;
      setContextData('currentPage_sh', this.currentPage);
      get(
        'api/orderbasic/getMemberOrderByManagerId?pageNum=' + p + '&pageSize=10'
      ).then((res) => {
        this.isLoading = false;
        res.data.data.list.forEach((req) => {
          if (req.orderSource == 4) {
            req.orderSkuList[0].skuRealPrice = 0;
          }
          req.orderSkuList.forEach((q) => {
            q.saleAttrCode = eval('(' + q.saleAttrCode + ')');
            if (q.value != '0mol/mol' && q.value != '0ppm' && q.value != null) {
              if (q.value.indexOf('mol/mol') != -1) {
                q.saleAttrCode.push({
                  attrName: '确认含量',
                  attrValue: q.value.replace(
                    'mol/mol',
                    'X10<sup>-6</sup>mol/mol'
                  ),
                });
              } else {
                q.saleAttrCode.push({
                  attrName: '确认含量',
                  attrValue: q.value,
                });
              }
            }
            q.saleAttrCode.forEach((k, index) => {
              if (k.attrName == '浓度范围') {
                q.saleAttrCode.splice(index, 1);
              }
            });
             Object.assign(q, { isTrue: 'false' });
          });
          for (let q = 0; q < req.orderSkuList.length; q++) {
            for (let j = q + 1; j < req.orderSkuList.length; j++) {
              if(req.orderSkuList[q].remark =='1'&& req.orderSkuList[j].remark=='1'){
                if (req.orderSkuList[q].skuName == req.orderSkuList[j].skuName&&req.orderSkuList[q].storeId == req.orderSkuList[j].storeId&&req.orderSkuList[q].prodCatId == req.orderSkuList[j].prodCatId&&req.orderSkuList[q].batchNumber == req.orderSkuList[j].batchNumber) {
                  for (
                    let p = 0;
                    p < req.orderSkuList[j].saleAttrCode.length;
                    p++
                  ) {
                    if (
                      req.orderSkuList[j].saleAttrCode[p].attrName == '多组分' ||
                      req.orderSkuList[j].saleAttrCode[p].attrName == '气体组分' ||
                      req.orderSkuList[j].saleAttrCode[p].attrName == '确认含量'
                    ) {
                      req.orderSkuList[q].saleAttrCode.push(
                        req.orderSkuList[j].saleAttrCode[p]
                      );
                    }
                  }
                }
              }
            }
          }
          req.orderSkuList[0].isTrue = 'true';
        });
        res.data.data.list.forEach((i)=>{
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q+1; j < i.orderSkuList.length; j++) {
              if(i.orderSkuList[q].remark =='1'&& i.orderSkuList[j].remark=='1'){
                if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName&&i.orderSkuList[q].storeId == i.orderSkuList[j].storeId&&i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId&&i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                  i.orderSkuList.splice(j,1);
                }
              }
            }
          }
        })
        res.data.data.list.forEach((i)=>{
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q+1; j < i.orderSkuList.length; j++) {
              if(i.orderSkuList[q].remark =='1'&& i.orderSkuList[j].remark=='1'){
                if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName&&i.orderSkuList[q].storeId == i.orderSkuList[j].storeId&&i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId&&i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                  i.orderSkuList.splice(j,1);
                }
              }
            }
          }
        })
        res.data.data.list.forEach((i)=>{
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q+1; j < i.orderSkuList.length; j++) {
              if(i.orderSkuList[q].remark =='1'&& i.orderSkuList[j].remark=='1'){
                if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName&&i.orderSkuList[q].storeId == i.orderSkuList[j].storeId&&i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId&&i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                  i.orderSkuList.splice(j,1);
                }
              }
            }
          }
        })
        this.order_list = res.data.data.list;
      });
    },
    // 获取订单
    getorder(e) {
      get(
        'api/orderbasic/getMemberOrderByManagerId?pageNum=' + e + '&pageSize=10'
      ).then((res) => {
        this.isLoading = false;
        res.data.data.list.forEach((req) => {
          if (req.orderSource == 4) {
            req.orderSkuList[0].skuRealPrice = 0;
          }
          req.orderSkuList.forEach((q) => {
            q.saleAttrCode = eval('(' + q.saleAttrCode + ')');
            if (q.value != '0mol/mol' && q.value != '0ppm' && q.value != null) {
              if (q.value.indexOf('mol/mol') != -1) {
                q.saleAttrCode.push({
                  attrName: '确认含量',
                  attrValue: q.value.replace(
                    'mol/mol',
                    'X10<sup>-6</sup>mol/mol'
                  ),
                });
              } else {
                q.saleAttrCode.push({
                  attrName: '确认含量',
                  attrValue: q.value,
                });
              }
            }
            q.saleAttrCode.forEach((k, index) => {
              if (k.attrName == '浓度范围') {
                q.saleAttrCode.splice(index, 1);
              }
            });
             Object.assign(q, { isTrue: 'false' });
          });
          for (let q = 0; q < req.orderSkuList.length; q++) {
            for (let j = q + 1; j < req.orderSkuList.length; j++) {
              if(req.orderSkuList[q].remark =='1'&& req.orderSkuList[j].remark=='1'){
                if (req.orderSkuList[q].skuName == req.orderSkuList[j].skuName&&req.orderSkuList[q].storeId == req.orderSkuList[j].storeId&&req.orderSkuList[q].prodCatId == req.orderSkuList[j].prodCatId&&req.orderSkuList[q].batchNumber == req.orderSkuList[j].batchNumber) {
                  for (
                    let p = 0;
                    p < req.orderSkuList[j].saleAttrCode.length;
                    p++
                  ) {
                    if (
                      req.orderSkuList[j].saleAttrCode[p].attrName == '多组分' ||
                      req.orderSkuList[j].saleAttrCode[p].attrName == '气体组分' ||
                      req.orderSkuList[j].saleAttrCode[p].attrName == '确认含量'
                    ) {
                      req.orderSkuList[q].saleAttrCode.push(
                        req.orderSkuList[j].saleAttrCode[p]
                      );
                    }
                  }
                }
              }
            }
          }
          req.orderSkuList[0].isTrue = 'true';
        });
        res.data.data.list.forEach((i)=>{
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q+1; j < i.orderSkuList.length; j++) {
              if(i.orderSkuList[q].remark =='1'&& i.orderSkuList[j].remark=='1'){
                if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName&&i.orderSkuList[q].storeId == i.orderSkuList[j].storeId&&i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId&&i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                  i.orderSkuList.splice(j,1);
                }
              }
            }
          }
        })
        res.data.data.list.forEach((i)=>{
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q+1; j < i.orderSkuList.length; j++) {
              if(i.orderSkuList[q].remark =='1'&& i.orderSkuList[j].remark=='1'){
                if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName&&i.orderSkuList[q].storeId == i.orderSkuList[j].storeId&&i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId&&i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                  i.orderSkuList.splice(j,1);
                }
              }
            }
          }
        })
        res.data.data.list.forEach((i)=>{
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q+1; j < i.orderSkuList.length; j++) {
              if(i.orderSkuList[q].remark =='1'&& i.orderSkuList[j].remark=='1'){
                if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName&&i.orderSkuList[q].storeId == i.orderSkuList[j].storeId&&i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId&&i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                  i.orderSkuList.splice(j,1);
                }
              }
            }
          }
        })
        this.order_list = res.data.data.list;
        this.totalNum = res.data.data.total;
      });
    },
    // 查询
    search() {
      this.currentPage = 1;
      this.isLoading = true;
      get('api/orderbasic/getMemberOrderByManagerId?pageNum=1&pageSize=10&orderNo=' + this.mes).then(
        (res) => {
          this.isLoading = false;
          if (res.data.data.list == '') {
            this.tishi = '未查到相关订单~';
            this.order_list = res.data.data.list;
          } else {
            res.data.data.list.forEach((req) => {
              if (req.orderSource == 4) {
                req.orderSkuList[0].skuRealPrice = 0;
              }
              req.orderSkuList.forEach((q) => {
                q.saleAttrCode = eval('(' + q.saleAttrCode + ')');
                if (q.value != '0mol/mol' && q.value != '0ppm' && q.value != null) {
              if (q.value.indexOf('mol/mol') != -1) {
                q.saleAttrCode.push({
                  attrName: '确认含量',
                  attrValue: q.value.replace(
                    'mol/mol',
                    'X10<sup>-6</sup>mol/mol'
                  ),
                });
              } else {
                q.saleAttrCode.push({
                  attrName: '确认含量',
                  attrValue: q.value,
                });
              }
            }
                q.saleAttrCode.forEach((k, index) => {
                  if (k.attrName == '浓度范围') {
                    q.saleAttrCode.splice(index, 1);
                  }
                });
                 Object.assign(q, { isTrue: 'false' });
              });
            for (let q = 0; q < req.orderSkuList.length; q++) {
            for (let j = q + 1; j < req.orderSkuList.length; j++) {
              if(req.orderSkuList[q].remark =='1'&& req.orderSkuList[j].remark=='1'){
                if (req.orderSkuList[q].skuName == req.orderSkuList[j].skuName&&req.orderSkuList[q].storeId == req.orderSkuList[j].storeId&&req.orderSkuList[q].prodCatId == req.orderSkuList[j].prodCatId&&req.orderSkuList[q].batchNumber == req.orderSkuList[j].batchNumber) {
                  for (
                    let p = 0;
                    p < req.orderSkuList[j].saleAttrCode.length;
                    p++
                  ) {
                    if (
                      req.orderSkuList[j].saleAttrCode[p].attrName == '多组分' ||
                      req.orderSkuList[j].saleAttrCode[p].attrName == '气体组分' ||
                      req.orderSkuList[j].saleAttrCode[p].attrName == '确认含量'
                    ) {
                      req.orderSkuList[q].saleAttrCode.push(
                        req.orderSkuList[j].saleAttrCode[p]
                      );
                    }
                  }
                }
              }
            }
          }
          req.orderSkuList[0].isTrue = 'true';
        });
        res.data.data.list.forEach((i)=>{
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q+1; j < i.orderSkuList.length; j++) {
              if(i.orderSkuList[q].remark =='1'&& i.orderSkuList[j].remark=='1'){
                if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName&&i.orderSkuList[q].storeId == i.orderSkuList[j].storeId&&i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId&&i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                  i.orderSkuList.splice(j,1);
                }
              }
            }
          }
        })
        res.data.data.list.forEach((i)=>{
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q+1; j < i.orderSkuList.length; j++) {
              if(i.orderSkuList[q].remark =='1'&& i.orderSkuList[j].remark=='1'){
                if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName&&i.orderSkuList[q].storeId == i.orderSkuList[j].storeId&&i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId&&i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                  i.orderSkuList.splice(j,1);
                }
              }
            }
          }
        })
        res.data.data.list.forEach((i)=>{
          for (let q = 0; q < i.orderSkuList.length; q++) {
            for (let j = q+1; j < i.orderSkuList.length; j++) {
              if(i.orderSkuList[q].remark =='1'&& i.orderSkuList[j].remark=='1'){
                if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName&&i.orderSkuList[q].storeId == i.orderSkuList[j].storeId&&i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId&&i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                  i.orderSkuList.splice(j,1);
                }
              }
            }
          }
        })
            this.order_list = res.data.data.list;
            this.totalNum = res.data.data.total;
          }
        }
      );
    },
    //订单详情
    ding_detail(e) {
      this.$router.push({
        name: 'Dingdan_detail',
        query: {
          //skuId: e.skuId,
          orderId: e.orderId,
        },
      });
    },
    //订单商品价格调整
    tiaojia(e) {
      this.$refs.form.dialogFormVisible = true;
      this.$refs.form.orderId = e.orderId;
      this.$refs.form.getOrderSku();
    },
    // 订单商品价格调整详情
    tiaojia_detail(e) {
      this.$refs.dform.dialogFormVisible = true;
      this.$refs.dform.orderId = e.orderId;
      this.$refs.dform.getOrderSku();
    },
    // 查看物流
    showLogistics(e) {
      this.isLoading1 = true;
      get(
        'api/orderexpress/getorderexpress?com=' +
          e.orderExt.expressId +
          '&num=' +
          e.orderExt.expressNum +
          ''
      ).then((res) => {
        if (
          res.data.data.returnCode == undefined ||
          res.data.data.status == 200
        ) {
          res.data.data.data[0].ftime = '#0bbd87';
          this.wuliu_list = res.data.data.data;
          this.isLoading1 = false;
          this.dialogFormVisible1 = true;
        } else {
          this.isLoading1 = false;
          this.$message.warning({
            message: res.data.data.message,
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.tishi {
  width: 90%;
  text-align: center;
  margin-top: 150px;
  font-size: 24px;
  color: rgb(139, 135, 135);
}
.right {
  width: 1006px;
  padding-top: 34px;
  padding-left: 27px;
  background: #fff;
  .spen {
    font-size: 26px;
    font-weight: 600;
    color: #616161;
  }
  .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .daohang {
      font-size: 15px;
      color: #272727;
      margin-right: 25px;
      height: 30px;

      .ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        width: 400px;
        li {
          list-style: none;
          cursor: pointer;
          &.router-link-exact-active.router-link-active {
            color: #3661fe;
          }
        }
      }
    }
    .search {
      margin-right: 150px;
      display: flex;
      input {
        border: 0;
        outline: none;
        border: 1px solid #a7a7a7;
        height: 37px;
        padding-left: 10px;
      }
      .fangdajing {
        width: 45px;
        height: 40px;
        border: 1px solid #a7a7a7;
        border-left: none;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .fenye {
    margin-top: 50px;
    margin-bottom: 20px;
  }
  .top {
    width: 100%;
    height: 42px;
    line-height: 42px;
    background: #f5f5f5;
    display: flex;
    margin-top: 30px;
    .biaoti {
      font-size: 16px;
      color: #888;
      margin-left: 20px;
      .danhao {
        color: #666;
        font-weight: 800;
      }
      img {
        width: 23px;
        height: 23px;
        margin-left: 10px;
      }
    }
    .dianpu {
      cursor: pointer;
    }
    .mar {
      margin-left: 40px;
    }
    .kefu {
      display: flex;
      align-items: center;
    }
  }
  .bottom {
    padding-bottom: 20px;
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
    display: flex;
    .pic {
      width: 180px;
      height: 136px;
      img {
        width: 100%;
        height: 100%;
        margin-top: 20px;
      }
    }
    .title1 {
      height: 100%;
      border-right: 1px solid #eaeaea;
      padding-right: 10px;
      .title {
        padding-top: 34px;
        display: flex;
        justify-content: space-between;
        .miaoshu {
          color: #333;
          width: 190px;
          margin-left: 10px;
          height: 40px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          cursor: pointer;
        }
        .shuliang {
          margin-left: 10px;
        }
      }
      .xiangsi {
        font-size: 14px;
        color: #9a9a9a;
        margin-top: 10px;
      }
      .shuxing {
        margin-left: 10px;
        font-size: 12px;
        color: #9a9a9a;
        /* margin-top: 10px; */
        display: flex;
      }
    }
    .tousu {
      width: 102px;
      border-right: 1px solid #eaeaea;
      text-align: center;
      padding-top: 34px;
      font-size: 14px;
      color: #333;
    }
    .beidaigouren {
      width: 158px;
      border-right: 1px solid #eaeaea;
      padding-top: 34px;
      /*padding-left: 25px;*/
    }
    .price {
      width: 158px;
      border-right: 1px solid #eaeaea;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .jiage {
        font-size: 14px;
        color: #333;
      }
      .yunfei {
        color: #a0a0a0;
        font-size: 12px;
      }
      .fangshi {
        color: #a0a0a0;
        font-size: 12px;
      }
    }
    .zhuangtai {
      width: 130px;
      padding-top: 34px;
      border-right: 1px solid #eaeaea;
      text-align: center;
      color: #333;
      .xiangqing {
        margin-top: 5px;
        cursor: pointer;
      }
      .wuliu {
        color: #dc7413;
        margin-top: 5px;
      }
      .quxiao {
        color: #a0a0a0;
        margin-top: 5px;
      }
    }
    .pay {
      width: 136px;
      padding-top: 34px;
      border-right: 1px solid #eaeaea;
      font-size: 12px;
      color: #333;
      text-align: center;
      .liji {
        color: #fff;
        background: #1850ff;
        padding: 5px 10px;
        width: 60px;
        border-radius: 5px;
        margin: 0 auto;
        cursor: pointer;
      }
      .liji1 {
        color: #fff;
        background: #ff902a;
        padding: 5px 10px;
        width: 60px;
        border-radius: 5px;
        margin: 0 auto;
        margin-top: 10px;
        cursor: pointer;
      }
      .daifu {
        cursor: pointer;
        margin-top: 5px;
      }
      .quxiao {
        cursor: pointer;
        margin-top: 5px;
      }
    }
  }
}
.tree1 {
  /*width: 100%;*/
  background: #f5f5f5;
  .tree {
    /*width: 1236px;*/
    margin: 0 auto;
    background: #fff;
    padding: 50px 40px 70px 35px;
    .tree-title {
      display: flex;
      align-items: center;
      padding: 10px 0 10px 10px;
      background: #f3f3f3;
      border: 1px solid #e9e9e9;
      .shangpin {
        width: 310px;
        margin-left: 140px;
      }
      .danjia {
        width: 107px;
        text-align: center;
      }
      .shuliang {
        width: 190px;
        text-align: center;
      }
      .xiaoji {
        text-align: center;
        width: 170px;
      }
      .caozuo {
        text-align: center;
        flex: 1;
      }
    }
    .tree-merchant {
      margin: 10px 0px;
      .tree-merchant-name {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        border-bottom: 2px solid #999;
      }
      .tree-goods {
        font-size: 14px;
        color: #4c4c4c;
        display: flex;
        padding-top: 10px;
        height: 140px;
        border: 1px solid #e4e4e4;
        padding-left: 10px;
        .goods-pic {
          width: 126px;
          height: 126px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .goods-jieshao {
          width: 123px;
          height: 115px;
          padding: 20px 10px 0 10px;
          margin-left: 10px;
          overflow: hidden;
        }
        .goods-xinghao {
          width: 180px;
          padding-top: 15px;
          margin-left: 20px;
          .goods-xinghao-list {
            display: flex;
            margin-top: 5px;
            color: #9c9c9c;
          }
        }
        .goods-danjia1 {
          width: 100px;
          padding-top: 20px;
          text-align: center;
        }
        .goods-shuliang1 {
          width: 166px;
          padding-top: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-left: 20px;
          .youhuo {
            color: #a8a8a8;
            margin-top: 10px;
          }
          .wuhuo {
            color: #e83f38;
          }
          &-num {
            display: flex;
            align-items: center;
            .L {
              cursor: pointer;
            }
            .R {
              cursor: pointer;
              margin-left: 10px;
            }
            .C {
              width: 50px;
              margin-left: 10px;
              input {
                width: 20px;
                border: none;
                outline: none;
                text-align: center;
                padding: 0 10px;
                margin-bottom: 10px;
                background: #fff;
              }
            }
          }
        }
        .goods-xiaoji1 {
          width: 195px;
          padding-top: 20px;
          text-align: center;
        }
        .goods-caozuo1 {
          width: 120px;
          padding-top: 20px;
          text-align: center;
          color: #343434;
          .shan {
            cursor: pointer;
          }
          .yiru {
            cursor: pointer;
            margin-top: 10px;
          }
        }
      }
    }
  }
  .jiesuan {
    width: 100%;
    height: 100px;
    background: #fff;
    .tree-bottom_C {
      width: 1150px;
      height: 80px;
      margin: 10px auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .jiesuan_L {
        display: flex;
        align-items: center;
        .quanxuan {
          padding-top: 5px;
          color: #343434;
        }
        .shanchu {
          margin-left: 20px;
          color: #484848;
          padding-top: 5px;
          cursor: pointer;
        }
        .shanchu:hover {
          color: red;
          border-bottom: 1px solid red;
        }
        .yiru {
          margin-left: 20px;
          color: #484848;
          padding-top: 5px;
          cursor: pointer;
        }
        .yiru:hover {
          border-bottom: 1px solid #484848;
        }
      }
      .jiesuan_R {
        font-size: 14px;
        color: #989898;
        display: flex;
        align-items: center;
        .yixuanze {
          .spen {
            color: #1a50fb;
          }
        }
        .img {
          width: 15px;
          height: 9px;
          margin-left: 10px;
        }
        .zongjia {
          margin-left: 40px;
          .money {
            color: #1b50fe;
          }
        }
        .tijiao {
          width: 84px;
          height: 28px;
          line-height: 28px;
          text-align: center;
          background: #1850ff;
          color: #fff;
          border-radius: 5px;
          margin-left: 17px;
          cursor: pointer;
        }
      }
    }
  }
  input {
    appearance: none;
    outline: none;
    margin-top: 10px;
    padding: 0;
    margin-right: 10px;
  }
  input[type='checkbox'] {
    position: relative;
    width: 20px;
    height: 20px;
    background: #d5d5d5;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    &:checked::after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      color: #333;
      text-align: center;
      line-height: 18px;
      background: url('https://static.98ep.com/img/BigCompany/a.png') no-repeat
        center;
    }
  }

  input[type='checkbox']:checked::after {
    content: '';
    background-color: #037df3;
    border-color: #037df3;
    background-color: #037df3;
  }
  input[type='checkbox']:checked::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 5px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    z-index: 1;
  }
  button {
    margin-top: 20px;
  }
}
</style>
